import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
const routes: Array<RouteRecordRaw> = [
  // {
  //   path: "/",
  //   component: () => import("@/layout/index.vue"),
  //   redirect: "/index",
  //   children: [
  //     // 需要layout的页面
  //     {
  //       path: "index",
  //       name: "index",
  //       meta: {
  //         title: "index",
  //       },
  //       component: () => import("@/pages/index.vue"),
  //     },
  //   ],
  // },
  {
    path: "/",
    component: () => import("@/pages/index.vue"),
    redirect: "/index",
    children: [
      {
        path: "index",
        name: "index",
        meta: {
          title: "index",
        },
        component: () => import("@/pages/index.vue"),
      },
    ],
  },
  // 提交页
  {
    path: "/submit",
    component: () => import("@/pages/submit.vue"),
  },
  // 分享页
  {
    path: "/share",
    component: () => import("@/pages/share.vue"),
  },
  // 不需要layout的页面
  // 替代vue2中的'*'通配符路径
  { path: "/:pathMatch(.*)*", redirect: "/" },
  // 退货回收页
  {
    path: "/thhs",
    component: () => import("@/pages/other/thhs.vue"),
  },
  // 寄存保障页
  {
    path: "/jcbz",
    component: () => import("@/pages/other/jcbz.vue"),
  },
  // 同城跑腿
  {
    path: "/tcpt",
    component: () => import("@/pages/other/tcpt.vue"),
  },
  // 玩转惠押
  {
    path: "/wzhy",
    component: () => import("@/pages/other/wzhy.vue"),
  },
  // 邀请有礼
  {
    path: "/yqyl",
    component: () => import("@/pages/other/yqyl.vue"),
  },
  // 应急变现8
  {
    path: "/yjbx8",
    component: () => import("@/pages/other/yjbx8.vue"),
  },
  // 应急变现4
  {
    path: "/yjbx4",
    component: () => import("@/pages/other/yjbx4.vue"),
  },
  // 应急变现4
  {
    path: "/yjbx0",
    component: () => import("@/pages/other/yjbx0.vue"),
  },
  // 玩转惠押
  {
    path: "/wzhy",
    component: () => import("@/pages/other/wzhy.vue"),
  },
  // 回收方式
  {
    path: "/hsfs",
    component: () => import("@/pages/other/hsfs.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(), // history 模式则使用 createWebHistory()
  routes,
});
export default router;
