<script setup lang="ts">
import FingerprintJS from 'fingerprintjs2';
const fingerprint = FingerprintJS.get((components: Object[]) => {
  const values = components.map((component: any, index) => {
    if (index === 0) { //把微信浏览器里UA的wifi或4G等网络替换成空,不然切换网络会ID不一样
      return component.value.replace(/\bNetType\/\w+\b/, '')
    }
    return component.value
  })
  // 生成最终id murmur   
  const murmur = FingerprintJS.x64hash128(values.join(''), 31)
  localStorage.setItem('DeviceNo', murmur)
})
</script>

<template>
  <router-view />
</template>

<style scoped></style>
